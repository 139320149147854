<template>
  <div class="spacing flex xs12 md12">
    <vac-card title="Spacing">
      <spacing-playgroud :title="''"/>
    </vac-card>
  </div>
</template>

<script>
import SpacingPlaygroud from './SpacingPlaygroud';

export default {
  name: 'spacing',
  components: { SpacingPlaygroud },
};
</script>
